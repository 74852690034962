<template>
  <v-container class="pa-3" fluid>
    <v-row dense class="mt-10">
      <v-col cols="12">
        <p class="titulo-user">Dicas</p>
      </v-col>
      <v-col cols="12" class="col-box">
        <v-card class="mx-auto mb-3" light v-for="i in itens" :key="i.id">
          <v-toolbar :color="i.color" dark>
            <v-toolbar-title>{{ i.categoria }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ i.titulo }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <!-- <v-list-item-action-text>agendar</v-list-item-action-text> -->
                <v-btn icon light>
                  <v-icon size="30" :color="i.color + ' lighten-1'">{{ i.icone }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  mounted() {
    //this.$tours["myTour"].start();
  },
  data: () => ({
    itens: [
      {
        id: 1,
        titulo: "Beber 2l de água",
        categoria: "Saúde Física",
        color: "blue",
        icone: "fab fa-youtube",
      },
      {
        id: 2,
        titulo: "Durma bem",
        categoria: "Saúde Mental",
        color: "warning",
        icone: "fas fa-bed",
      },
      {
        id: 3,
        titulo: "10 alimentos para reforçar imunidade",
        categoria: "Saúde na Mesa",
        color: "red",
        icone: "fab fa-apple",
      },
    ],
  }),
};
</script>
<style>
.icone-umbrella {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
.icone-umbrella .umbrella {
  font-size: 150px;
}

.v-card__title {
  word-break: initial;
  padding: initial;
}

.box-padrao {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: #6567a8 3px solid !important;
  border-radius: 4px !important;
}

.box-dicas {
  height: 120px;
}

.box-falar-nick {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: #6567a8 3px solid !important;
  border-radius: 4px !important;
}

.titulo-user {
  text-align: center;
  color: #fff;
  font-size: 48px;
}

.subtitulo-user {
  text-align: center;
  color: #fff;
  font-size: 20px;
}

.v-badge {
  display: block !important;
}

.message {
  color: #fff !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background: #6567a8 !important;
}

.v-application .headline {
  font-size: 12px !important;
}
</style>
